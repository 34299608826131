import React, { useContext, useState } from "react";
import { toast } from "react-toastify";
import { Web3Context } from "../web3Context";

export default function RevealURI() {
  const web3Context = useContext(Web3Context);
  const [input, setInput] = useState("");

  const handleInputChange = (event: any) => {
    setInput(event.target?.value);
  };

  async function reveal() {
    if (!web3Context.contract || !web3Context.userAccount) {
      toast.error("Impossible to change URI 🚫");
      return;
    }

    try {
      const contract = web3Context.contract;
      const transaction = await contract.reveal(input);
      toast.promise(transaction.wait(), {
        pending: "Revealing URI ⛏️",
        success: "URI revealed 👌",
        error: "Transaction rejected 🚫",
      });
      await transaction.wait();
    } catch (e: any) {
      console.log(e);
      toast.error("Transaction rejected 🚫");
    }
  }
  return (
    <>
      <div className="project-footer d-flex justify-content-center align-items-center mt-4 mt-md-5">
        <div className="input-area d-flex flex-column">
          <input
            type="text"
            placeholder="IPFS URI for metadata"
            onChange={handleInputChange}
          />

          <button onClick={reveal} className="btn input-btn mt-2">
            Reveal Token URI
          </button>
        </div>
      </div>
    </>
  );
}
