import { Contract, ethers } from "ethers";

export const getContract = (
  contractAddress: string,
  contractAbi: any
): Contract | null | undefined => {
  if (typeof window !== "undefined") {
    // You now have access to `window`
    const { ethereum } = window as any;

    if (!ethereum) {
      console.error(
        "Could not get connected contract: ethereum object does not exist!"
      );
      return null;
    }

    const provider = new ethers.providers.Web3Provider(ethereum);
    const signer = provider.getSigner();
    return new ethers.Contract(
      contractAddress as string,
      contractAbi.abi,
      signer
    );
  }
};
