import React, { useContext, useState } from 'react';
import { Web3Context } from '../web3Context';
import { Web3DataContext } from '../web3DataContext';
import { toast } from 'react-toastify';
import { ethers } from 'ethers';
import { keccak256 } from 'ethers/lib/utils';

export default function Mint() {
	const web3DataContext = useContext(Web3DataContext);
	const web3Context = useContext(Web3Context);
	const [count, setCount] = useState(0);
	const price = web3DataContext.mintPrice;
	const saleStatus = web3DataContext.saleStatus;
	const getCurrentSupply = web3DataContext.getCurrentSupply;
	const merkleTree = web3DataContext.merkleTree;
	const account = web3Context.userAccount;
	const getUserCollection = web3DataContext.getUserCollection;

	function handlePlus() {
		let previousCount = count;
		if (previousCount === 5) return;
		setCount(previousCount + 1);
	}

	function handleMinus() {
		let previousCount = count;
		if (previousCount === 0) return;
		setCount(previousCount - 1);
	}

	async function mint() {
		if (
			!web3Context.contract ||
			price === 'null' ||
			saleStatus === 0 ||
			!web3Context.userAccount
		) {
			toast.error('Impossible to mint 🚫');
			return;
		}

		if (saleStatus === 1 && count !== 0 && merkleTree && account) {
			const leaf = keccak256(account);
			const proof = merkleTree.getHexProof(leaf);
			try {
				const contract = web3Context.contract;
				const transaction = await contract.presaleMint(proof, count, {
					value: ethers.utils.parseEther((Number(price) * count).toString()),
				});
				toast.promise(transaction.wait(), {
					pending: 'Minting in progress ⛏️',
					success: 'NFT Minted 👌',
					error: 'Transaction rejected 🚫',
				});
				await transaction.wait();

				getCurrentSupply(contract);
				getUserCollection(contract, account);
			} catch (e: any) {
				console.log(e);
				toast.error('Transaction rejected 🚫');
			}
		}
		if (saleStatus === 2 && count !== 0 && account) {
			try {
				const contract = web3Context.contract;
				const transaction = await contract.mint(count, {
					value: ethers.utils.parseEther((Number(price) * count).toString()),
				});
				toast.promise(transaction.wait(), {
					pending: 'Minting in progress ⛏️',
					success: 'NFT Minted 👌',
					error: 'Transaction rejected 🚫',
				});
				await transaction.wait();
				getCurrentSupply(contract);
				getUserCollection(contract, account);
			} catch (e: any) {
				console.log(e);
				toast.error('Transaction rejected 🚫');
			}
		}
	}

	return (
		<>
			{/* <pre>{JSON.stringify(saleStatus, null, 2)}</pre> */}
			<div className='project-footer d-flex justify-content-center align-items-center mt-4 mt-md-5'>
				<button onClick={handleMinus} className='btn btn btn-smaller mr-4'>
					<i className='fa-solid fa-minus' />
				</button>
				<button onClick={mint} className='btn btn-bordered-white btn-smaller'>
					<i className='fa-solid fa-rocket mr-md-2 ' />
					Mint [{count}]
				</button>
				<button onClick={handlePlus} className='btn btn btn-smaller ml-4'>
					<i className='fa-solid fa-plus' />
				</button>
			</div>
		</>
	);
}
