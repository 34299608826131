import { IRoadmapData } from "../../pages/Home";

export default function Roadmap({
  roadmapData,
}: {
  roadmapData: IRoadmapData;
}) {
  return (
    <section className="work-area pb-2" id="roadmap">
      <div className="container">
        <div className="row">
          <div className="col-12">
            {/* Intro */}
            <div className="intro d-flex justify-content-between align-items-end mb-4">
              <div className="intro-content">
                <span className="intro-text">
                  {roadmapData.header.sub_heading}
                </span>
                <h3 className="mt-3 mb-0">{roadmapData.header.heading}</h3>
              </div>
            </div>
          </div>
        </div>
        <div className="row items">
          {roadmapData?.body?.map((item, idx: number) => {
            return (
              <div key={`wd_${idx}`} className="col-12 col-sm-6 col-lg-4 item">
                {/* Single Work */}
                <div className="single-work">
                  <div className="d-flex  ">
                    <i className={item.icon} />
                    <h5 className="ml-4">{item.step}</h5>
                  </div>
                  <h4>{item.title}</h4>
                  <ul>
                    {item?.content?.map((line: string, idx: number) => {
                      return (
                        <li key={idx}>
                          <p>{line}</p>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}
