import { IStepsData } from "../../pages/Home";

export default function Steps({ stepsData }: { stepsData: IStepsData }) {
  return (
    <section className="content-area pb-2" id="steps">
      <div className="container">
        <div className="row align-items-center justify-content-center">
          <div className="">
            <div className="content intro">
              <span className="intro-text">{stepsData.header.sub_heading}</span>
              <h3>{stepsData.header.heading}</h3>
              <p>{stepsData.header.excerpt}</p>
              <ul className="list-unstyled items mt-5">
                {stepsData.body?.map((item, idx) => {
                  return (
                    <li key={`cd_${idx}`} className="item">
                      {/* Content List */}
                      {item?.position === "right" && (
                        <div className="content-list d-flex align-items-center">
                          <div className="content-body mr-4 w-100">
                            <h3
                              className={`px-3 py-1 m-0 step-progress w-${item.progress}`}
                            >
                              {item.progress} %
                            </h3>
                            <ul>
                              {item?.content.map(
                                (line: string, idx: number) => {
                                  return (
                                    <li key={idx}>
                                      <p>{line}</p>
                                    </li>
                                  );
                                }
                              )}
                            </ul>
                          </div>
                          <div className="content-icon">
                            <span>
                              <i className={item.icon} />
                            </span>
                          </div>
                        </div>
                      )}
                      {item?.position === "left" && (
                        <div className="content-list d-flex align-items-center">
                          <div className="content-icon">
                            <span>
                              <i className={item.icon} />
                            </span>
                          </div>
                          <div className="content-body ml-4 w-100">
                            <h3
                              className={`px-3 py-1 m-0 step-progress w-${item.progress}`}
                            >
                              {item.progress} %
                            </h3>
                            <ul>
                              {item?.content.map(
                                (line: string, idx: number) => {
                                  return (
                                    <li key={idx}>
                                      <p>{line}</p>
                                    </li>
                                  );
                                }
                              )}
                            </ul>
                          </div>
                        </div>
                      )}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
