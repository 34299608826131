const data = {
  img: "/img/logo.png",
  copyright: "All Rights Reserved",
  owner: "Acadee Blockchain Academy",
  ownerLink: "https://www.acadee-formation.com/",
  socialData: [
    {
      id: 1,
      link: "https://www.linkedin.com/company/acadee-crypto-academy/",
      icon: "icon-social-linkedin",
    },
    {
      id: 2,
      link: "https://twitter.com/nft_blockheadz",
      icon: "icon-social-twitter",
    },
  ],
  widgetData: [
    {
      id: 1,
      text: "Terms & Credits",
      link: "/legals",
    },
  ],
  copyright_builder: "©2022 website & smart contracts by",
  builder: "Ridge Coding",
  link_builder: "https://www.ridgecoding.com",
};

export default function Footer() {
  return (
    <footer className="footer-area">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-md-8 text-center">
            {/* Footer Items */}
            <div className="footer-items">
              {/* Logo */}
              <a className="navbar-brand" href="/">
                <img src={data.img} alt="" />
              </a>
              {/* Social Icons */}
              <div className="social-icons d-flex justify-content-center my-4">
                {data.socialData.map((item, idx) => {
                  return (
                    <a
                      key={`fsd_${idx}`}
                      className="facebook"
                      href={item.link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className={item.icon} />
                      <i className={item.icon} />
                    </a>
                  );
                })}
              </div>
              <ul className="list-inline">
                {data.widgetData.map((item, idx) => {
                  return (
                    <li key={`fwd_${idx}`} className="list-inline-item">
                      <a href={item.link}>{item.text}</a>
                    </li>
                  );
                })}
              </ul>
              {/* Copyright Area */}
              <div className="copyright-area pt-4">
                {data.copyright}{" "}
                <a href={data.ownerLink} rel="noreferrer" target="_blank">
                  {data.owner}
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-12 col-md-8 text-center">
            {/* Footer Items */}
            <div className="footer-items">
              {/* Copyright Area */}
              <div className="copyright-area pb-4">
                {data.copyright_builder}{" "}
                <a href={data.link_builder} rel="noreferrer" target="_blank">
                  {data.builder}
                </a>
              </div>
            </div>
            {/* Scroll To Top */}
            <div id="scroll-to-top" className="scroll-to-top">
              <a href="#header" className="smooth-anchor">
                <i className="fa-solid fa-arrow-up" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
