import { useContext } from "react";
import { ICollectionsData } from "../../pages/Mint";
import { IMetaData } from "../../services/getNftData";
import { Web3Context } from "../../web3/web3Context";

export default function CollectionCard({
  collectionData,
  item,
}: {
  collectionData: ICollectionsData;
  item: IMetaData;
}) {
  const web3Context = useContext(Web3Context);
  const contract = web3Context.contract;
  if (item) {
    return (
      <div className="col-12 col-md-6 col-lg-4 mt-3">
        <div className="card project-card">
          <div className="media">
            <a
              target="blank"
              href={`${collectionData.openSeaBaseUrl}/${contract?.address}/${item.id}`}
            >
              <img
                className="card-img-top avatar-max-lg"
                src={item.image}
                alt=""
              />
            </a>
            <div className="media-body ml-4">
              <div className="countdown-times">
                <h6 className="my-2">{item.name}</h6>
              </div>
            </div>
          </div>

          {/* Project Footer */}
          <div className="project-footer d-flex align-items-center mt-4 mt-md-5">
            <a
              target="blank"
              href={`${collectionData.openSeaBaseUrl}/${contract?.address}/${item.id}`}
            >
              <button className="btn btn-bordered-white btn-smaller">
                View on OpenSea
              </button>
            </a>
          </div>
        </div>
      </div>
    );
  } else return <></>;
}
