import React, { useContext, useState } from "react";
import { Web3Context } from "../web3Context";
import { Web3DataContext } from "../web3DataContext";
import { toast } from "react-toastify";

export default function ChangeStatus() {
  const web3DataContext = useContext(Web3DataContext);
  const web3Context = useContext(Web3Context);
  const saleStatus = web3DataContext.saleStatus;

  const [status, setStatus] = useState(saleStatus);

  async function changeStatus() {
    if (!web3Context.contract || !web3Context.userAccount) {
      toast.error("Impossible to change status 🚫");
      return;
    }

    try {
      const contract = web3Context.contract;
      const transaction = await contract.setSaleStatus(status);
      toast.promise(transaction.wait(), {
        pending: "Updating status ⛏️",
        success: "Status Changed 👌",
        error: "Transaction rejected 🚫",
      });
      await transaction.wait();
      web3DataContext.getSaleStatus(contract);
    } catch (e: any) {
      console.log(e);
      toast.error("Transaction rejected 🚫");
    }
  }

  const handleStatusChange = (event: any) => {
    setStatus(event.target?.value);
  };

  const statusToString = () => {
    switch (saleStatus) {
      case undefined:
        break;
      case 1:
        return "Presale";
      case 2:
        return "Public";
      case 0:
        return "Closed";
    }
  };

  return (
    <>
      {/* <pre>{JSON.stringify(saleStatus, null, 2)}</pre> */}
      <div className="project-footer d-flex justify-content-center align-items-center mt-4 mt-md-5">
        <div className="input-area d-flex flex-column">
          Current Status is {statusToString()}
          <select onChange={handleStatusChange} name="">
            <option value={undefined}>Select</option>
            <option value={0}>Closed</option>
            <option value={1}>Presale</option>
            <option value={2}>Public</option>
          </select>
          <button onClick={changeStatus} className="btn input-btn mt-2">
            Change Status
          </button>
        </div>
      </div>
    </>
  );
}
