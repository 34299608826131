import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { IHeroData } from '../../pages/Home';
import { Web3DataContext } from '../../web3/web3DataContext';

function Hero({ heroData }: { heroData: IHeroData }) {
	const web3DataContext = useContext(Web3DataContext);
	const price = web3DataContext.mintPrice;
	const saleStatus = web3DataContext.saleStatus;
	const collectionSize = web3DataContext.collectionSize;
	const currentSupply = web3DataContext.currentSupply;

	return (
		<section className='hero-section mt-5'>
			<div className='container'>
				<div className='hero-container row align-items-center justify-content-center'>
					<div className='col-12 col-md-6 col-lg-9 text-center'>
						{/* Hero Content */}
						<div className='hero-content'>
							<div className='intro text-center mb-5'>
								<span className='intro-text'>{heroData.sub_heading}</span>
								<h1 className='mt-4'>{heroData.heading_line1}</h1>
								<h1 className='mt-4'>{heroData.heading_line2}</h1>
								<p className='text-white'>{heroData.content}</p>
							</div>
							{/* Buttons */}
							<div className='button-group mb-4'>
								<Link
									to='/mint'
									className='btn btn-bordered active smooth-anchor'
								>
									<i className='icon-rocket mr-2' />
									Mint Now
								</Link>
								<a className='btn btn-bordered-white' href='#about'>
									<i className='icon-info mr-2' />
									Learn More
								</a>
							</div>
							{price !== 'null' &&
								saleStatus !== undefined &&
								collectionSize &&
								currentSupply && (
									<>
										{saleStatus === 0 && (
											<span>
												<i className='fa-solid fa-ban mr-2'></i>
												Sale is Closed
											</span>
										)}
										{saleStatus === 1 && (
											<span>
												<i className='fa-solid fa-unlock mr-2'></i>
												Presale is On
											</span>
										)}
										{saleStatus === 2 && (
											<span>
												<i className='fa-solid fa-user-group mr-2'></i>
												Sale is Open
											</span>
										)}
										<br />
										<span className='text-center '>
											<i className='fa-solid fa-tags mr-2'></i>{' '}
											{price ? price : 0} MATIC per BlockHeadz
										</span>
										<br />
										<span>
											<i className='fa-solid fa-layer-group mr-2'></i>
											{currentSupply}/{collectionSize} BlockHeadz Minted
										</span>
									</>
								)}
						</div>
					</div>
					<div>
						<img src={`/img/${heroData.image}`} alt='blockheadz_hero' />
					</div>
				</div>
			</div>
		</section>
	);
}

export default Hero;
