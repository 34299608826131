import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";
import Steps from "../components/Home/Steps";
import Hero from "../components/Home/Hero";
import Roadmap from "../components/Home/Roadmap";
import ModalMenu from "../components/Modal/ModalMenu";
import stepsData from "../data/steps.json";
import roadmapData from "../data/roadmap.json";
import aboutData from "../data/about.json";
import heroData from "../data/hero.json";
import teamData from "../data/team.json";
import About from "../components/Home/About";
import Team from "../components/Home/Team";

export interface IStepsData {
  header: {
    sub_heading: string;
    heading: string;
    excerpt: string;
  };

  body: {
    id: number;
    icon: string;
    position: string;
    progress: string;
    content: string[];
  }[];
}

export interface IRoadmapData {
  header: { sub_heading: string; heading: string };
  body: {
    id: number;
    icon: string;
    step: string;
    title: string;
    content: string[];
  }[];
}

export interface IAboutData {
  sub_heading: string;
  heading: string;
  content: string[];
  image1: string;
  image2: string;
}

export interface IHeroData {
  sub_heading: string;
  heading_line1: string;
  heading_line2: string;
  content: string;
  image: string;
}

export interface ITeamData {
  header: { sub_heading: string; heading: string };
  team: IteamMember[];
}

export type IteamMember = {
  id: number;
  img: string;
  name: string;
  designation: string;
  socials: { icon: string; link: string }[];
};

export default function Home() {
  return (
    <div>
      <Header />
      <Hero heroData={heroData} />
      <About aboutData={aboutData} />
      <Steps stepsData={stepsData} />
      <Roadmap roadmapData={roadmapData} />
      <Team teamData={teamData} />
      <Footer />
      <ModalMenu />
    </div>
  );
}
