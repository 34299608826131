export default function DiscordBtn() {
  return (
    <a
      href="https://discord.com/invite/U9mCNXFnDs"
      target="_blank"
      rel="noreferrer"
    >
      <ul className="navbar-nav action">
        <li className="nav-item ml-2">
          <div className="btn ml-lg-auto btn-bordered-white">
            <i
              className="fa-brands fa-discord mr-md-2 "
              style={{ minHeight: "10px" }}
            />
            Discord
          </div>
        </li>
      </ul>
    </a>
  );
}
