import React from "react";

// importing MyRouts where we located all of our theme
import Routes from "./router/routes";

function App() {
  return (
    <div>
      <Routes />
    </div>
  );
}

export default App;
