import React from "react";
import Breadcrumb from "../components/Breadcrumb/Breadcrumb";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";
import Minting from "../components/Minting/Minting";
import ModalMenu from "../components/Modal/ModalMenu";
import mintingData from "../data/minting.json";
import collectionData from "../data/collection.json";
import Collection from "../components/Collection/CollectionList";

export interface IMintingData {
  heading: string;
  sub_heading: string;
}

export interface ICollectionsData {
  id: number;
  img: string;
  blockchain: string;
  title: string;
  collectionLink: string;
  blockchainScanUrl: string;
  openSeaBaseUrl: string;
}

export default function Mint() {
  return (
    <div>
      <Header />
      <Breadcrumb title="Mint" subpage="" page="Mint" />
      <Minting mintingData={mintingData} collectionData={collectionData} />
      <Collection collectionData={collectionData} />
      <Footer />
      <ModalMenu />
    </div>
  );
}
