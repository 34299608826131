import isConnectedOn from '../toolsFunctions/isConnectedOn';

import { useContext, useEffect, useState } from 'react';
import { MetamaskState, Web3Context } from '../web3Context';
import { ethers } from 'ethers';

const AskForMetamask = () => {
	const web3Context = useContext(Web3Context);
	const [AccountLoad, setAccountLoad] = useState(false);

	const askForMetamask = async (requestToUser = false) => {
		if (!web3Context.setUserAccount || !web3Context.setMetamaskState) return;

		setAccountLoad(true);
		web3Context.setMetamaskState(MetamaskState.CONNECTING);
		try {
			const accounts = await (window as any).ethereum.request({
				method: requestToUser ? 'eth_requestAccounts' : 'eth_accounts',
			});
			if (accounts.length > 0) {
				if (await isConnectedOn(ethers.utils.hexValue(137))) {
					web3Context.setUserAccount(accounts[0]);
					web3Context.setMetamaskState(MetamaskState.CONNECTED);
				} else {
					web3Context.setMetamaskState(MetamaskState.BAD_NETWORK);
					web3Context.setUserAccount(null);
				}
			} else {
				web3Context.setMetamaskState(MetamaskState.NOT_CONNECTED);
				web3Context.setUserAccount(null);
			}
		} catch (e) {
			web3Context.setMetamaskState(MetamaskState.NOT_CONNECTED);
			web3Context.setUserAccount(null);
		}
		setAccountLoad(false);
	};

	useEffect(() => {
		if (!web3Context.setMetamaskState || !web3Context.setUserAccount) return;

		if (typeof (window as any).ethereum === 'undefined') {
			web3Context.setMetamaskState(MetamaskState.METAMASK_MISSING);
			web3Context.setUserAccount(null);
		} else {
			(window as any).ethereum.on('accountsChanged', () => {
				askForMetamask(false);
			});
			(window as any).ethereum.on('chainChanged', () => {
				window.location.reload();
			});
			askForMetamask();
		}
	}, []);

	const connexionButton = () => {
		return (
			<div>
				{web3Context.metamaskState === MetamaskState.CONNECTED && (
					<ul
						className='navbar-nav action'
						onClick={() => {
							askForMetamask(true);
						}}
					>
						<li className='nav-item ml-2'>
							<div className='btn ml-lg-auto btn'>
								<i className='icon-wallet mr-md-2' />
								{web3Context.userAccount &&
									web3Context.userAccount.substring(0, 12).concat('...')}
							</div>
						</li>
					</ul>
				)}
				{web3Context.metamaskState === MetamaskState.BAD_NETWORK && (
					<ul
						className='navbar-nav action'
						onClick={() => {
							askForMetamask(true);
						}}
					>
						<li className='nav-item ml-2'>
							<div className='btn ml-lg-auto btn'>
								<i className='fa-solid fa-ban mr-md-2' />
								Bad Network - Use POLYGON
							</div>
						</li>
					</ul>
				)}
				{(web3Context.metamaskState === MetamaskState.NOT_CONNECTED ||
					AccountLoad) && (
					<ul
						className='navbar-nav action'
						onClick={() => {
							askForMetamask(true);
						}}
					>
						<li className='nav-item ml-2'>
							<div className='btn ml-lg-auto btn'>
								<i className='icon-wallet mr-md-2' />
								Connect to Metamask
							</div>
						</li>
					</ul>
				)}
			</div>
		);
	};

	return (
		<div>
			{web3Context.metamaskState === MetamaskState.METAMASK_MISSING ? (
				<ul className='navbar-nav action'>
					<li className='nav-item ml-2'>
						<div className='btn ml-lg-auto btn-bordered-white'>
							<i className='icon-wallet mr-md-2' />
							Install Metamask
						</div>
					</li>
				</ul>
			) : (
				connexionButton()
			)}
		</div>
	);
};

export default AskForMetamask;
