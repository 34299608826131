import React from "react";
import Breadcrumb from "../components/Breadcrumb/Breadcrumb";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";
import LegalsContent from "../components/Legals/LegalsContent";
import ModalMenu from "../components/Modal/ModalMenu";
import legalData from "../data/terms.json";

export default function Legals() {
  return (
    <div>
      <Header />
      <Breadcrumb title="Terms & Credits" subpage="" page="Credits" />
      <LegalsContent legalData={legalData} />
      <Footer />
      <ModalMenu />
    </div>
  );
}
