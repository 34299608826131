import { useContext } from 'react';
import { ICollectionsData } from '../../pages/Mint';
import Mint from '../../web3/components/mint';
import { Web3DataContext } from '../../web3/web3DataContext';

export default function MintingCard({
	collectionData,
}: {
	collectionData: ICollectionsData;
}) {
	const web3DataContext = useContext(Web3DataContext);
	const price = web3DataContext.mintPrice;
	const saleStatus = web3DataContext.saleStatus;
	const collectionSize = web3DataContext.collectionSize;
	const currentSupply = web3DataContext.currentSupply;

	return (
		<div className='container d-flex justify-content-center'>
			<div className=' card project-card w-75'>
				<div className='media'>
					<a
						href={collectionData.collectionLink}
						target='_blank'
						rel='noreferrer'
					>
						<img
							className='d-none d-md-flex card-img-top avatar-max-lg'
							src={`/img/${collectionData.img}`}
							alt=''
						/>
					</a>
					<div className='media-body ml-4'>
						<a
							href={collectionData.collectionLink}
							target='_blank'
							rel='noreferrer'
						>
							<h4 className='m-0'>{collectionData.title}</h4>
						</a>
						<div className='single-item mt-2'>
							<span>Sale Status : </span>
							{saleStatus === 0 && <span>Closed</span>}
							{saleStatus === 1 && <span>Presale</span>}
							{saleStatus === 2 && <span>Public</span>}
						</div>
						<div className='single-item mt-2'>
							{saleStatus === 0 && <span>Max NFT per wallet (closed)</span>}
							{saleStatus === 1 && (
								<>
									<span>Max NFT per wallet (presale) : </span> <span>10</span>
								</>
							)}
							{saleStatus === 2 && (
								<>
									<span>Max NFT per wallet (public) : </span> <span>20</span>
								</>
							)}
						</div>
					</div>
				</div>
				{/* Project Body */}
				<div className='card-body'>
					<div className='item-progress'>
						<div className='progress mt-4 mt-md-5'>
							<div
								className='progress-bar'
								role='progressbar'
								style={{
									width: `${
										currentSupply &&
										collectionSize &&
										(currentSupply / collectionSize) * 100
									}%`,
								}}
								aria-valuenow={
									currentSupply &&
									collectionSize &&
									(currentSupply / collectionSize) * 100
								}
								aria-valuemin={0}
								aria-valuemax={100}
							>
								{currentSupply &&
									collectionSize &&
									((currentSupply / collectionSize) * 100).toFixed(1)}
								%
							</div>
						</div>
						<div className='progress-sale d-flex justify-content-between mt-3'>
							<span>
								<i className='fa-solid fa-layer-group mr-md-2'></i>
								{currentSupply}/{collectionSize} BlockHeadz Minted
							</span>
							<span>
								<i className='fa-solid fa-tag mr-md-2 ' />
								{price} MATIC
							</span>
						</div>
					</div>
				</div>
				{/* Project Footer */}
				<Mint />
				{/* Blockchain Icon */}
				<a
					href={collectionData.blockchainScanUrl}
					target='_blank'
					rel='noreferrer'
					className='blockchain-icon'
				>
					<img src={`/img/${collectionData.blockchain}`} alt='' />
				</a>
				<div className='items d-flex justify-content-center'>
					{/* Single Item */}
					<a
						href={collectionData.collectionLink}
						target='_blank'
						rel='noreferrer'
					>
						<button className=' mt-5 btn btn-bordered-white'>
							Explore collection on OpenSea
						</button>
					</a>
				</div>
			</div>
		</div>
	);
}
