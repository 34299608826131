const isConnectedOn = async (targetChain: string): Promise<boolean> => {
  if ((window as any).ethereum === undefined) {
    return false;
  }

  if (targetChain === "any") return true;

  const currentChainId = await (window as any).ethereum.request({
    method: "eth_chainId",
  });

  return currentChainId === targetChain;
};

export default isConnectedOn;
