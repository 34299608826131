import { BigNumber, Contract, ethers } from 'ethers';
import { createContext, useState, useEffect, useContext } from 'react';
import { Web3Context } from './web3Context';
import whiteList from '../data/whiteList.json';
import { keccak256 } from 'ethers/lib/utils';
import MerkleTree from 'merkletreejs';
import getNftData, { IMetaData } from '../services/getNftData';

export interface Web3DataContextInterface {
	mintPrice: string | null;
	saleStatus: number | null;
	getSaleStatus: (contract: Contract | undefined | null) => Promise<void>;
	collectionSize: number | undefined;
	currentSupply: number | undefined;
	getCurrentSupply: (contract: Contract | undefined | null) => Promise<void>;
	merkleTree: MerkleTree | undefined;
	userCollection: IMetaData[] | undefined;
	getUserCollection: (
		contract: Contract | undefined | null,
		user: string | null
	) => Promise<void>;
	owner: string | undefined;
}

export const Web3DataContextContent: Web3DataContextInterface = {
	mintPrice: null,
	saleStatus: null,
	getSaleStatus: Promise.reject,
	collectionSize: undefined,
	currentSupply: undefined,
	getCurrentSupply: Promise.reject,
	merkleTree: undefined,
	userCollection: undefined,
	getUserCollection: Promise.resolve,
	owner: undefined,
};

export const Web3DataContext = createContext<Web3DataContextInterface>(
	Web3DataContextContent
);

export default function Web3DataContextProvider({ children }: any) {
	const web3Context = useContext(Web3Context);

	const contract = web3Context.contract;
	const user = web3Context.userAccount;

	const [mintPrice, setMintPrice] = useState(Web3DataContextContent.mintPrice);
	const [saleStatus, setSaleStatus] = useState(
		Web3DataContextContent.saleStatus
	);
	const [collectionSize, setCollectionSize] = useState<number | undefined>(
		Web3DataContextContent.collectionSize
	);
	const [currentSupply, setCurrentSupply] = useState<number | undefined>(
		Web3DataContextContent.currentSupply
	);
	const [userCollection, setUserCollection] = useState<IMetaData[] | undefined>(
		Web3DataContextContent.userCollection
	);

	const [owner, setOwner] = useState<string | undefined>(
		Web3DataContextContent.owner
	);

	const merkleLeaves = whiteList.map((address) => keccak256(address));
	const merkleTree = new MerkleTree(merkleLeaves, keccak256, { sort: true });

	const getPrice = async (
		contract: Contract | undefined | null,
		saleStatus: number | null
	) => {
		if (!contract || !saleStatus) return;
		try {
			if (saleStatus === 1) {
				const res = await contract.presale_price();
				setMintPrice(ethers.utils.formatEther(res));
			}
			if (saleStatus === 2) {
				const res = await contract.public_price();
				setMintPrice(ethers.utils.formatEther(res));
			}
		} catch (e) {
			console.log(e);
			setMintPrice('null');
		}
	};

	const getSaleStatus = async (contract: Contract | undefined | null) => {
		if (!contract) return;
		try {
			const res = await contract.saleStatus();
			setSaleStatus(res);
		} catch (e) {
			console.log(e);
			setSaleStatus(null);
		}
	};

	const getCollectionSize = async (contract: Contract | undefined | null) => {
		if (!contract) return;
		try {
			const res = await contract.collectionSize();
			const size = parseInt(res._hex, 16);
			setCollectionSize(size);
		} catch (e) {
			console.log(e);
			setCollectionSize(undefined);
		}
	};

	const getCurrentSupply = async (contract: Contract | undefined | null) => {
		if (!contract) return;
		try {
			const res = await contract.totalSupply();
			const size = parseInt(res._hex, 16);
			setCurrentSupply(size);
		} catch (e) {
			console.log(e);
			setCurrentSupply(undefined);
		}
	};

	const getUserCollection = async (
		contract: Contract | undefined | null,
		user: string | null
	) => {
		if (!contract || !user) return;
		try {
			const res = await contract.tokensOfOwner(user);

			const rawCollection = await Promise.all(
				res.map(async (token: BigNumber) => {
					const tokenURI = await contract.tokenURI(token._hex);
					return { id: parseInt(token._hex, 16), tokenURI: tokenURI };
				})
			);

			if (rawCollection?.length > 0) {
				const collection = await getNftData(rawCollection);
				setUserCollection(collection);
			}
		} catch (e) {
			console.log(e);
			setUserCollection(undefined);
		}
	};

	const getOwner = async (contract: Contract | undefined | null) => {
		if (!contract) return;
		try {
			const res = await contract.owner();
			setOwner(res);
		} catch (e) {
			console.log(e);
			setUserCollection(undefined);
		}
	};

	useEffect(() => {
		getSaleStatus(contract);
		getCollectionSize(contract);
		getCurrentSupply(contract);
		getPrice(contract, saleStatus);
		getUserCollection(contract, user);
		getOwner(contract);
	}, [contract, saleStatus, user]);

	const value = {
		mintPrice,
		saleStatus,
		getSaleStatus,
		collectionSize,
		currentSupply,
		getCurrentSupply,
		merkleTree,
		userCollection,
		getUserCollection,
		owner,
	};

	return (
		<Web3DataContext.Provider value={value}>
			{children}
		</Web3DataContext.Provider>
	);
}
