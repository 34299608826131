import { useContext } from 'react';

import CollectionCard from './CollectionCard';
import { Web3DataContext } from '../../web3/web3DataContext';
import { ICollectionsData } from '../../pages/Mint';
import { IMetaData } from '../../services/getNftData';
import { Web3Context } from '../../web3/web3Context';

function Collection({ collectionData }: { collectionData: ICollectionsData }) {
	const web3Context = useContext(Web3Context);
	const web3DataContext = useContext(Web3DataContext);
	const getUserCollection = web3DataContext.getUserCollection;
	const collection = web3DataContext.userCollection;

	async function handleClick() {
		getUserCollection(web3Context.contract, web3Context.userAccount);
	}

	return (
		<>
			<div className='container '>
				<div className='row justify-content-center text-center'>
					<div className='col-12'>
						<div className='intro text-center'>
							<div className='intro-content'>
								<h3 className='mt-5 mb-0'>Your Collection</h3>
							</div>
						</div>
					</div>
				</div>
				<div className='row justify-content-center text-center'>
					<div className='col-12'>
						<div className='intro text-center'>
							<div className='intro-content'>
								<button
									className='btn btn-bordered btn-smaller'
									onClick={async () => {
										await handleClick();
									}}
								>
									Refresh
								</button>
							</div>
						</div>
					</div>
				</div>

				<div className='row explore-items items inner collection'>
					{collection &&
						collection.map((item: IMetaData, idx: number) => {
							return (
								<CollectionCard
									collectionData={collectionData}
									item={item}
									key={`pd_${idx}`}
								/>
							);
						})}
				</div>
			</div>
		</>
	);
}

export default Collection;
