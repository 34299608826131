import React, { useContext } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "../pages/Home";
import NotFound from "../pages/404";
import FAQ from "../pages/FAQ";
import Legals from "../pages/Legals";
import Mint from "../pages/Mint";

import { Web3DataContext } from "../web3/web3DataContext";
import { Web3Context } from "../web3/web3Context";
import Admin from "../pages/Admin";

function Router() {
  const web3DataContext = useContext(Web3DataContext);
  const web3Context = useContext(Web3Context);

  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/legals" element={<Legals />} />
          <Route path="/mint" element={<Mint />} />
          <Route path="*" element={<NotFound />} />
          {web3DataContext.owner?.toLowerCase() ===
            web3Context.userAccount?.toLowerCase() && (
            <Route path="/admin" element={<Admin />} />
          )}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default Router;
