import { ITeamData, IteamMember } from '../../pages/Home';

export default function Team({ teamData }: { teamData: ITeamData }) {
	return (
		<section className='team-area pb-2'>
			<div className='container'>
				<div className='row'>
					<div className='col-12'>
						{/* Intro */}
						<div className='intro d-flex justify-content-between align-items-end m-0'>
							<div className='intro-content'>
								<span className='intro-text'>
									{teamData.header.sub_heading}
								</span>
								<h3 className='mt-3 mb-0'>{teamData.header.heading}</h3>
							</div>
						</div>
					</div>
				</div>
				<div className=''>
					<div className=' items'>
						<div className='row justify-content-center '>
							{/* Single Slide */}
							{teamData.team.map((item: IteamMember, idx: number) => {
								return (
									<div key={`td_${idx}`} className='col-12 col-xl-4 item'>
										{/* Team Card */}
										<div className='card team-card text-center'>
											<div className='team-photo d-inline-block'>
												<img
													className='mx-auto'
													src={`/img/${item.img}`}
													alt=''
												/>
											</div>
											{/* Team Content */}
											<div className='team-content mt-3'>
												<h4 className='mb-0'>{item.name}</h4>
												<span className='d-inline-block mt-2 mb-3'>
													{item.designation}
												</span>
												{/* Social Share */}
												<div className='social-share'>
													<ul className='d-flex justify-content-center list-unstyled'>
														{item.socials.map(
															(
																item: { link: string; icon: string },
																idx: number
															) => {
																return (
																	<li key={`sd_${idx}`}>
																		<a
																			href={item.link}
																			target={'_blank'}
																			rel='noreferrer'
																		>
																			<i className={item.icon} />
																		</a>
																	</li>
																);
															}
														)}
													</ul>
												</div>
											</div>
										</div>
									</div>
								);
							})}
						</div>
						<div className='swiper-pagination' />
					</div>
				</div>
			</div>
		</section>
	);
}
