import React from 'react';
import Breadcrumb from '../components/Breadcrumb/Breadcrumb';
import Footer from '../components/Footer/Footer';
import Header from '../components/Header/Header';
import ModalMenu from '../components/Modal/ModalMenu';
import Airdrop from '../web3/components/airdrop';
import ChangeStatus from '../web3/components/changeSatus';
import RevealURI from '../web3/components/revealURI';
import Withdraw from '../web3/components/withdraw';

export default function Admin() {
	return (
		<div>
			<Header />
			<Breadcrumb title='Admin' subpage='' page='Admin' />
			<ChangeStatus />
			<RevealURI />
			<Airdrop />
			<Withdraw />
			<Footer />
			<ModalMenu />
		</div>
	);
}
