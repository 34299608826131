import { ICollectionsData, IMintingData } from "../../pages/Mint";

import MintingCard from "./MintingCard";

function Minting({
  mintingData,
  collectionData,
}: {
  mintingData: IMintingData;
  collectionData: ICollectionsData;
}) {
  return (
    <>
      <div className="row justify-content-center">
        <div className="col-12 col-md-8 col-lg-7">
          {/* Intro */}
          <div className="intro text-center">
            <div className="intro-content">
              <span className="intro-text">{mintingData.sub_heading}</span>
              <h3 className="mt-3 mb-0">{mintingData.heading} </h3>
            </div>
          </div>
        </div>
      </div>
      <MintingCard collectionData={collectionData} />
    </>
  );
}

export default Minting;
