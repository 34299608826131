import React from "react";
import Breadcrumb from "../components/Breadcrumb/Breadcrumb";
import Faq from "../components/Faq/Faq";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";
import ModalMenu from "../components/Modal/ModalMenu";
import data from "../data/faq.json";

export interface IFaqData {
  id: string;
  btnClass: string;
  target: string;
  quote: string;
  contentId: string;
  contentClass: string;
  content: string[];
}

export default function FAQ() {
  return (
    <div>
      <Header />
      <Breadcrumb title="Frequently Asked Questions" subpage="" page="FAQ" />
      <Faq data={data} />
      <Footer />
      <ModalMenu />
    </div>
  );
}
