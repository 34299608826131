import { Link } from "react-router-dom";
import AskForMetamask from "../../web3/components/askForMetamask";
import DiscordBtn from "./DiscordBtn";

function Header() {
  return (
    <header id="header">
      {/* Navbar */}
      <nav
        data-aos="zoom-out"
        data-aos-delay={800}
        className="navbar gameon-navbar navbar-expand"
      >
        <div className="container header">
          {/* Logo */}
          <a className="navbar-brand" href="/">
            <img src="/img/logo_blockheadz.png" alt="ABHS Logo" />
          </a>
          <div className="ml-auto" />
          {/* Navbar Links */}
          <ul className="navbar-nav items mx-auto">
            <li className="nav-item">
              <Link to="/" className="nav-link">
                Home
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/mint" className="nav-link">
                Mint
              </Link>
            </li>
            <li className="nav-item">
              <a
                href="https://drive.google.com/file/d/1zRGNFtAdbvVvkAV8IvnZ_u2T8drtmdBr/view"
                target="_blank"
                rel="noreferrer"
                className="nav-link"
              >
                White Paper
              </a>
            </li>
            <li className="nav-item">
              <Link to="/faq" className="nav-link">
                FAQ
              </Link>
            </li>
          </ul>
          {/* Navbar Toggler */}
          <ul className="navbar-nav toggle">
            <li className="nav-item">
              <a
                href="#"
                className="nav-link"
                data-toggle="modal"
                data-target="#menu"
              >
                <i className="icon-menu m-0" />
              </a>
            </li>
          </ul>
          {/* Navbar Action Button */}
          <DiscordBtn />
          <AskForMetamask />
        </div>
      </nav>
    </header>
  );
}

export default Header;
