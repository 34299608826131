import { IAboutData } from "../../pages/Home";

export default function About({ aboutData }: { aboutData: IAboutData }) {
  return (
    <section className="content-area pb-2" id="about">
      <div className="container">
        <div className="d-flex flex-row align-items-center">
          <div className="col-12 col-md-6">
            <div className="content intro">
              <span className="intro-text">{aboutData.sub_heading}</span>
              <h3>{aboutData.heading}</h3>
              <ul>
                {aboutData.content?.map((line: string, idx: number) => {
                  return (
                    <li key={idx} style={{ listStyle: "none" }}>
                      <p className="text-justify">{line}</p>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>

          {/* Blockchain Animation */}
          <div className="row row-cols-2 about-img-wrapper">
            <div className="about-img-container-1">
              <img
                className="col about-img"
                alt="about_img_1"
                src={`/img/${aboutData.image1}`}
              />
              <span className="about-placeholder-1"></span>
              <span className="about-placeholder-2"></span>
            </div>
            <div className="about-img-container-2">
              <img
                className="col about-img"
                alt="about_img_1"
                src={`/img/${aboutData.image2}`}
              />
              <span className="about-placeholder-1"></span>
              <span className="about-placeholder-2"></span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
