import React, { createContext, useState } from 'react';
import { Contract } from 'ethers';

// Import Smart Contract
import { getContract } from './toolsFunctions/getContract';
import contractAbi from './abi/contractAbi.json';
const contractAddress = '0x1f4EF37FfAB9127DCd272b871Dc05862a2576B55';

export enum MetamaskState {
	CONNECTING,
	METAMASK_MISSING,
	NOT_CONNECTED,
	BAD_NETWORK,
	CONNECTED,
}

interface Web3ContextInterface {
	metamaskState: MetamaskState;
	userAccount: null | string;
	contract: null | Contract | undefined;
	setUserAccount?: (value: any) => void;
	setMetamaskState?: (value: any) => void;
}

export const Web3ContextContent: Web3ContextInterface = {
	userAccount: null,
	contract: getContract(contractAddress, contractAbi),
	metamaskState: MetamaskState.METAMASK_MISSING,
};

export const Web3Context =
	createContext<Web3ContextInterface>(Web3ContextContent);

export const Web3ContextProvider: React.FC = ({ children }: any) => {
	const [userAccount, setUserAccount] = useState(
		Web3ContextContent.userAccount
	);
	const [metamaskState, setMetamaskState] = useState(
		Web3ContextContent.metamaskState
	);
	const contract = Web3ContextContent.contract;

	return (
		<Web3Context.Provider
			value={{
				userAccount,
				setUserAccount,
				metamaskState,
				setMetamaskState,
				contract,
			}}
		>
			{children}
		</Web3Context.Provider>
	);
};
