import React, { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import { Web3Context } from '../web3Context';

export default function Airdrop() {
	const web3Context = useContext(Web3Context);
	const [input, setInput] = useState('');

	const handleInputChange = (event: any) => {
		setInput(event.target?.value);
	};

	async function airdrop() {
		if (!web3Context.contract || !web3Context.userAccount || input === '') {
			toast.error('Impossible to airdrop 🚫');
			return;
		}

		try {
			const contract = web3Context.contract;
			const transaction = await contract.airdrop(input);
			toast.promise(transaction.wait(), {
				pending: 'Airdroping ⛏️',
				success: 'Token Airdroped 👌',
				error: 'Transaction rejected 🚫',
			});
			await transaction.wait();

			setInput('');
		} catch (e: any) {
			console.log(e);
			toast.error('Transaction rejected 🚫');
		}
	}
	return (
		<>
			<div className='project-footer d-flex justify-content-center align-items-center mt-4 mt-md-5'>
				<div className='input-area d-flex flex-column'>
					<input
						type='text'
						placeholder='Address of the beneficiary'
						onChange={handleInputChange}
					/>
					<div>
						<button onClick={airdrop} className='btn btn btn-smaller'>
							<i className='fa-solid fa-rocket mr-md-2 ' />
							Airdrop
						</button>
					</div>
				</div>
			</div>
		</>
	);
}
