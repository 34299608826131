import axios from 'axios';

export interface IMetaData {
	id: number;
	name: string;
	description: string;
	image: string;
}

export default async function getNftData(
	collection: { id: number; tokenURI: string }[] | undefined
): Promise<IMetaData[] | undefined> {
	if (!collection) return;
	const collectionMetaData = await Promise.all(
		collection.map(async (token) => {
			const HTTPTokenURI = token.tokenURI.replace(
				'ipfs://',
				'https://ipfs.io/ipfs/'
			);
			const meta = await axios.get(HTTPTokenURI);

			return {
				...meta.data,
				id: token.id,
				image: meta.data.image.replace('ipfs://', 'https://ipfs.io/ipfs/'),
			};
		})
	);

	return collectionMetaData;
}
