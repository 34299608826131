import { IFaqData } from "../../pages/FAQ";

const initData = {
  sub_heading: "FAQ",
  heading:
    "Each BLOCKHEADZ is unique and generated from rare features like backgrounds, eyes, clothes, accessories...",
  content: "",
};

export default function Faq({ data }: { data: IFaqData[] }) {
  return (
    <section className="faq-area pt-0">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-md-8 col-lg-7">
            {/* Intro */}
            <div className="intro text-center">
              <div className="intro-content">
                <span className="intro-text">{initData.sub_heading}</span>
                <h3 className="mt-3 mb-0">{initData.heading}</h3>
                <p>{initData.content}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-12">
            {/* FAQ Content */}
            <div className="faq-content">
              {/* Gameon Accordion */}
              <div className="accordion" id="gameon-accordion">
                <div className="row justify-content-center">
                  <div className="col-12 col-md-10">
                    {/* Single Accordion Item */}
                    {data.map((item, idx) => {
                      return (
                        <div
                          key={`fd_${idx}`}
                          className="single-accordion-item p-3"
                        >
                          {/* Card Header */}
                          <div className="card-header bg-inherit border-0 p-0">
                            <h2 className="m-0">
                              <button
                                className={item.btnClass}
                                type="button"
                                data-toggle="collapse"
                                data-target={item.target}
                              >
                                {item.quote}
                              </button>
                            </h2>
                          </div>
                          <div
                            id={item.contentId}
                            className={item.contentClass}
                            data-parent="#gameon-accordion"
                          >
                            {/* Card Body */}
                            <ul className="card-body py-3">
                              {item.content.map((line: string, idx: number) => {
                                return <li key={idx}>{line}</li>;
                              })}
                            </ul>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
