import React, { useContext } from "react";
import { Web3Context } from "../web3Context";

import { toast } from "react-toastify";

export default function Withdraw() {
  const web3Context = useContext(Web3Context);

  async function withdraw() {
    if (!web3Context.contract || !web3Context.userAccount) {
      toast.error("Impossible to withdraw 🚫");
      return;
    }

    try {
      const contract = web3Context.contract;
      const transaction = await contract.withdraw();
      toast.promise(transaction.wait(), {
        pending: "Withdrawing funds ⛏️",
        success: "Funds Withdrawn 👌",
        error: "Transaction rejected 🚫",
      });
      await transaction.wait();
    } catch (e: any) {
      console.log(e);
      toast.error("Transaction rejected 🚫");
    }
  }

  return (
    <>
      {/* <pre>{JSON.stringify(saleStatus, null, 2)}</pre> */}
      <div className="project-footer d-flex justify-content-center align-items-center mt-4 mt-md-5">
        <div className="input-area d-flex flex-column">
          <button
            onClick={withdraw}
            className="btn btn-bordered input-btn mt-2"
          >
            Withdraw Funds
          </button>
        </div>
      </div>
    </>
  );
}
